.readytoGet {
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.35px;
}
.footNav {
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.239px;
}
a {
  color: inherit; /* Use the default text color */
  text-decoration: none; /* Remove underline */
}

a:link,
a:visited {
  color: inherit; /* Use the default text color for unvisited and visited links */
}

a:hover,
a:active {
  color: inherit; /* Use the default text color for hover and active states */
}
.mobileFooterready {
  display: flex;
}
.desktopFooterReady {
  display: none;
}
.mobileAddress {
  display: none;
}
.desktopAddress {
  display: flex;
}
@media only screen and (max-width: 600px) {
  .footNav {
    font-size: 13.51px;
  }
  .mobileFooterready {
    display: none;
  }
  .desktopFooterReady {
    display: block;
  }
  .mobile-footer {
    font-size: 13px;
  }
  .readytoGet {
    font-size: 22px;
  }
  .mobile-footer {
    flex-wrap: wrap;
    justify-content: center;
  }
  .mobileAddress {
    display: block;
  }
  .desktopAddress {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footNav {
    font-size: 16.51px;
  }
}
