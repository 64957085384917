body {
  font-family: "DM Sans", sans-serif;
}
.scheduleIDetails {
  margin: 1rem;
}

.scheduleIDetails table {
  width: 100%;
  border-collapse: collapse;
}

.scheduleIDetails th,
.scheduleIDetails td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.scheduleIDetails th {
  background-color: #f2f2f2;
}
