body {
  background-color: #ffffff !important;
}
.desktopVideo {
  display: block;
}
.MobileVideo {
  display: none;
}
.getInTouch {
  color: #216fb8;
  /* leading-trim: both;
  text-edge: cap; */
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.7%;
}
.empower {
  color: #fff;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 64px */
}
.grantDesktop {
  color: #fff;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 140.7%; /* 33.768px */
  display: block;
}
.revolutionText {
  color: #07113d;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 64px */
}
.cardHeading {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
}
.cardText {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.counterText {
  color: #42bfe0;
  font-size: 90px;
  font-family: sans-serif;
}
.costText {
  color: white;
  font-size: 55px;
}
.unbeatableText {
  color: #ededed;

  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.simplyfy {
  color: #000;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
}
.unlockFunds {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 104.2%; /* 66.688px */
}
.goodbye {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mobileImg {
  display: none;
}
.deskopImg {
  display: block;
}
.byeText {
  padding-bottom: 30px;
}
.desktopFooter {
  display: block;
}
.mobileFooter {
  display: none;
}
.mobileSwiper {
  display: none;
}
.desktopSwiper {
  display: flex;
}
.revolutionMobile {
  display: none;
}
.revolutiondesktop {
  display: block;
}
.DesktopEmpower {
  display: block;
}
.mobileEmpower {
  display: none;
}
.grantMobile {
  display: none;
}
.imgRupees {
  object-fit: contain;
}
.bgImage {
  position: relative;
  padding: 0px 60px;
  margin-top: 5%;
  display: block;
}
.bgImagem {
  display: none;
}
.downloadNow {
  padding: 8px 30px;
}
@media only screen and (max-width: 1100px) {
  .unbeatableText {
    font-size: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .bgImagem {
    position: relative;
    margin-top: 5%;
    display: block;
  }
  .bgImage {
    display: none;
  }
  .imgRupees {
    object-fit: cover;
  }
  .grantDesktop {
    display: none;
  }
  .desktopVideo {
    display: none;
  }
  .MobileVideo {
    display: block;
  }
  .mobileEmpower {
    display: block;
  }
  .DesktopEmpower {
    display: none;
  }
  .empower {
    color: #fff;
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 30px */
  }
  .grantMobile {
    color: #fff;
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.7%; /* 21.105px */
    display: block;
  }

  .mobileImg {
    display: block;
  }
  .deskopImg {
    display: none;
  }
  .revolutionText {
    color: #07113d;

    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 33px */
    letter-spacing: -0.9px;
  }

  .counterText {
    color: #42bfe0;
    font-family: sans-serif;
    font-size: 52px;
    font-style: normal;
    font-weight: 500;
    line-height: 160.7%;
    text-align: center;
  }

  .costText {
    color: #fff;
    font-family: "DM Sans", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 160.7%;
    text-align: center;
  }
  .unbeatableText {
    color: #ededed;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .byeText {
    padding: 0;
  }
  .simplyFyLaout {
    display: flex;
    justify-content: center;
  }
  .simplyfy {
    color: #000;
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.48px;
  }
  .goodbye {
    color: #000;
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    display: flex;
  }
  .unlockFunds {
    color: #000;
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 100% */
  }
  .desktopFooter {
    display: none;
  }
  .mobileFooter {
    display: block;
  }
  .mobileSwiper {
    display: block;
  }
  .desktopSwiper {
    display: none;
  }
  .revolutionMobile {
    display: block;
  }
  .revolutiondesktop {
    display: none;
  }
  .mobileImgFooter {
    height: 500px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .tablet {
    margin-top: 20px;
  }
  .empower {
    font-size: 40px;
  }
  .grant {
    font-size: 20px;
  }
  .revolutionText {
    font-size: 34px;
  }
  .counterText {
    font-size: 60px;
  }
  .costText {
    font-size: 22px;
  }
  .unbeatableText {
    font-size: 15px;
    margin-top: 20%;
  }
  .simplyfy {
    font-size: 22px;
  }
  .unlockFunds {
    font-size: 50px;
  }
  .goodbye {
    font-size: 16px;
  }
  .downloadNow {
    padding: 8px 8px;
  }
}

@media (max-width: 320px) {
  .counterText {
    color: #42bfe0;
    font-family: sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 160.7%;
    text-align: center;
  }
  .costText {
    color: #fff;
    font-family: "DM Sans", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 160.7%;
    text-align: center;
  }
  .unbeatableText {
    color: #ededed;
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .readytoGet {
    font-size: 12px;
  }
  .grantMobile {
    color: #fff;
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.7%;
    display: block;
  }
  .empower {
    color: #fff;
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  .mobileImgFooter {
    height: 400px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .tablet {
    margin-top: 0;
  }
  .grantDesktop {
    font-size: 16px;
  }
}

.submit-btn {
  background-color: #216fb8;
}
.contact-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto 50px;
  padding: 20px;
  background: linear-gradient(
      rgba(5, 117, 230, 0.12) 0%,
      rgba(2, 27, 121, 0.13) 100%
    ),
    rgb(255, 255, 255);
  border-radius: 10px;
  position: relative;
}
.contact-container h2 {
  font-size: 24px;
  font-weight: 700;
  color: #07113d;
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
}
.contact-container .form-label {
  font-size: 14px;
  margin-bottom: 5px;
}
.astrick {
  color: red;
}
.error-fields {
  font-size: 14px;
  line-height: 1;
  color: red;
}
.error-input {
  border: 1px solid red;
}
.textarea-field {
  resize: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.toster {
  position: absolute;
  top: 10px;
  border-radius: 10px;
  padding: 10px;
  left: 10px;
  right: 10px;
}
.toster.success {
  background: #42bfe0;
}
.toster.error {
  background: #f3c2c2;
}

@media screen and (max-width: 767px) {
  .contact-container {
    padding: 20px;
    border-radius: 0;
  }
}
