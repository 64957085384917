
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button.btn-new {
  padding: 3px 33px;
  border-radius: 8px;
  color: #fff;
  background-color: #42bfe0;
  border: 0;
  font-weight: 700;
  width: 165px;
}

button.btn-disabled {
  padding: 3px 33px;
  border-radius: 8px;
  color: #fff;
  background-color: #dddddd;
  border: 0;
  font-weight: 700;
  width: 165px;
}

.pink {
  background-color: #cc0066 !important;
}

.bg-yellow {
  background-color: #ffe699;
}

.home-banner .button-section {
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;
}

.home-banner .button-section button.btn.btn-light span {
  color: #aa1fe1;
}
.home-banner .button-section button.btn.btn-light {
  padding: 5px 22px;
  color: #000;
  font-size: 24px;
  border-radius: 100px;
  font-weight: 700;
  z-index: 1;
}

.home-banner .banner-content {
  color: #fff;
}

.home-banner .banner-content h2 {
  font-size: 70px;

  font-weight: 800;
}
.home-banner .banner-content h3 {
  font-size: 42px;
  font-weight: 600;
  border-bottom: 4px solid #ab1cd3;
  width: 40%;
  padding: 35px 0px 44px 0px;
}
.home-banner .banner-content h4 {
  font-size: 32px;
  font-weight: 800;
  margin-top: 40px;
}

.Ewacalculator-section .table-inner {
  background-color: #ffffff;
  padding: 10px 37px 0px 37px;
  border-radius: 15px;
}

.Ewacalculator-section .table-inner .table {
  background-color: #fff;
  width: 100%;
}

.Ewacalculator-section .table-inner .table tr {
  border: 0 !important;
}

.Ewacalculator-section .table-inner .table tr:nth-last-child(1) {
  border: 2px solid #fff !important;
  background-color: #000050;
  color: #fff !important;
  font-weight: 700;
}

.Ewacalculator-section .table-inner .table td {
  padding: 10px 15px;
  border: 1px solid #000;
}

.Ewacalculator-section .table-inner .table-header {
  background-color: #42bfe0;
  text-align: center;
  color: #fff;
  font-weight: 700;

  font-size: 20px;
}

.Ewacalculator-section .table-inner .table table {
  border: 1px solid #000;
}

.Ewacalculator-section .table-inner input.form-control {
  padding: 3px 11px 3px 22px;
  background: #f4f4f4;
  text-align: inherit;
}

.Ewacalculator-section .table-inner input.form-control.new{
  padding: 3px 30px 3px 22px !important;
}

.caclRowGrid {
  display: block;
  padding: 5px 0;
  border-bottom: 1px solid #c9c9c9;
}

.table > :not(:first-child) {
  border-top: 0px !important;
}

/* .caclRowGrid:nth-child(1) {
  border-bottom: 0px !important;
} */

.caclRowGrid > span {
  display: inline-block;
}

.caclRowGrid > span:first-child {
  width: 70%;
  padding-left: 10px;
  border-top: 0px;
}

.caclRowGrid > span:last-child {
  width: 30%;
}

.caclRowGridBtns {
  padding: 2px;
}

.caclRowGrid > .text-danger {
  padding: 0;
  margin: 0;
  line-height: initial;
  height: inherit;
  text-align: right;
}

.inputField {
  background: #fff;
  position: relative;
}

.inputField .rs {
  position: absolute;
  top: -3px;
}
.inputField .per {
  position: absolute;
  top: -3px;
  right: 4px;
  background: none;
}

.blueBgSec {
  background-color: #000050;
  min-height: 100vh !important;
  display: flex;
  align-items: center;
}

.calculator_logo {
  /* width: 22%; */
  width: 155px;
}

.boldText {
  font-weight: bold;
}

@media only screen and (max-width: 1200px) {
  /* .home-banner:before {
    display: none;
  } */
}

@media only screen and (max-width: 767px) {
  .Ewacalculator-section {
    padding-bottom: 20px;
  }
  .Ewacalculator-section .table-inner {
    padding: 16px 0px;
  }

  .inputField .per {
    /* top: -8px; */
    right: 10px;
  }

  button.btn-new {
    width: auto;
  }

  .inputField .rs {
    top: -7px;
  }

  .Ewacalculator-section .table-inner input.form-control {
    padding: 0px 23px;
  }

  .Ewacalculator-section .table-inner .table-header {
    font-size: 14px;
    padding: 7px 0;
  }

  .caclRowGrid > span:first-child {
    margin-bottom: 3px;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
  }

  .caclRowGrid > span:last-child {
    width: 100%;
    padding: 0px 10px;
  }

  .home-banner:before {
    /* display: none; */
  }

  .home-banner {
    padding: 23px 12px;
    text-align: center;
  }

  .home-banner .banner-content h3 {
    width: 100%;
    padding: 4px 0px 24px 0px;
    font-size: 30px;
  }

  .home-banner .button-section {
    margin-bottom: 125px;
  }

  .home-banner .banner-content h2 {
    font-size: 40px;
  }

  .home-banner .banner-content {
    position: relative;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .home-banner .banner-content h3 {
    width: 100%;
    font-size: 30px;
  }

  .home-banner .banner-content h2 {
    font-size: 40px;
  }

  .home-banner .banner-content {
    position: relative;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home-banner .banner-content h3 {
    width: 100%;
    font-size: 36px;
  }

  .home-banner .banner-content h2 {
    font-size: 46px;
  }

  .home-banner .banner-content {
    position: relative;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .home-banner .banner-content h3 {
    width: 100%;
    font-size: 36px;
  }

  .home-banner .banner-content h2 {
    font-size: 46px;
  }

  .home-banner .banner-content {
    position: relative;
  }
}
