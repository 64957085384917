.bankText {
  font-size: 32px;
  font-weight: 700 !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.cta-btn-outline {
  border: 1px solid #09733e;
  padding: 13px 31px !important;
  font-size: 14px;
  border-radius: 2px;
}
.cta-btn-outline:hover {
  background-color: #28a745;
  color: white;
  border: 1px solid #28a745;
}
.bankLogo {
  width: 80%;
}
.bankLogo2 {
  width: 40%;
}
.contact {
  font-family: sans-serif;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #09733e;
}
.contact:hover {
  color: white;
}
@media screen and (max-width: 768px) {
  .bankLogo {
    width: 80%;
  }
}
