.tabletTop {
  display: none;
}
@media only screen and (max-width: 600px) {
  .mobileHide {
    display: none;
  }
  .tabletTop {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .mobileHide {
    display: none;
  }
  .tabletTop {
    display: block;
  }
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: #ffffff;
    color: #000000;
  }
}
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: portrait) {

    .tabletTop {
      display: none;
    }
}
